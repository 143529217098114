<template>
    <div class="register_page">
        <!-- 语言切换 -->
        <div style="position: absolute;top: 20px;right: 55px;">
            <el-dropdown @command="handlanguage">
                <span class="el-dropdown-link" style="width: 112px;display: inline-block;">
                    {{ language.language }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="CHS">简体中文</el-dropdown-item>
                    <el-dropdown-item command="TC">繁體中文</el-dropdown-item>
                    <el-dropdown-item command="EN">English</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="register_title">
          <div v-if="!showtitle">{{ language.logo }}</div>
          <div v-if="showtitle">{{ language.logo }}{{ language.title2 }}</div>
        </div>
        <!-- 手机验证 -->
        <div class="register_box" v-if="type == 0">
            <div style="text-align: center;font-weight: 600;font-size: 24px;">{{ language.RetrievePassword }}</div>
            <!-- 手机号找回 -->
            <!-- <div style="margin-top:20px">
                <div>{{ language.mobile_number }}</div>
                <el-input class="register_input" v-model="phone" type="text" :placeholder="language.mobile_number"></el-input>
            </div> -->
            <!-- 邮箱找回 -->
            <div style="margin-top:20px">
                <div>{{ language.Email }}</div>
                <el-input class="register_input" v-model="Email" type="text" :placeholder="language.Email"></el-input>
            </div>
            <div style="width:100%;display: flex;justify-content: center;border:none;margin-top:100px">
                <el-button style="width:100%;border-radius: 10px;box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);"
                    type="primary" @click="send()">{{ language.Send }}</el-button>
            </div>
        </div>
        <!-- 新密码设置 -->
        <div class="register_box" v-if="(type == 1)">
            <div style="text-align: center;font-weight: 600;font-size: 24px;">{{ language.RetrievePassword }}</div>
            <!-- 手机验证码 -->
            <div style="margin-top:20px">
                <div>{{ language.VerificationCode }}</div>
                <el-input class="register_input" v-model="VerificationCode" type="text"
                    :placeholder="language.VerificationCode"></el-input>
            </div>
            <div style="color: red;font-size: 11px;">{{ language.NoteChecktheinboxortrash }}</div>
            <!-- 新密码 -->
            <div style="margin-top:20px;position: relative;">
                <div>{{ language.New_password }}</div>
                <!-- <el-input class="register_input" type="text" v-model="New_password" :placeholder="language.New_password"></el-input> -->
                <el-input style="box-sizing: border-box;" v-model="New_password"
                    :type="passwordicon ? 'password' : 'text'" :placeholder="language.New_password"></el-input>
                <span v-if="passwordicon" style="" @click="eyebtn()" class="iconeye3"></span>
                <span v-else style="" @click="eyebtn()" class="iconeye4"></span>
            </div>
            <!-- 确认密码 -->
            <div style="margin-top:20px;position: relative;">
                <div>{{ language.Confirm_Password }}</div>
                <!-- <el-input class="register_input" type="text" v-model="Confirm_Password"
                    :placeholder="language.Confirm_Password"></el-input> -->
                    <el-input style="box-sizing: border-box;" v-model="Confirm_Password"
                    :type="passwordicon2 ? 'password' : 'text'" :placeholder="language.Confirm_Password"></el-input>
                <span v-if="passwordicon2" style="" @click="eyebtn2()" class="iconeye3"></span>
                <span v-else style="" @click="eyebtn2()" class="iconeye4"></span>
            </div>
            <!-- 提交按钮 -->
            <div style="width:100%;display: flex;justify-content: center;border:none;margin-top:100px">
                <el-button style="width:100%;border-radius: 10px;box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);"
                    @click="ClickModify()" type="primary">{{ language.ClickModify }}</el-button>
            </div>
        </div>
        <!-- 修改成功提示跳转 -->
        <div class="register_box register_success_box" v-if="(type == 2)">
            <div style="text-align: center;font-weight: 600;font-size: 24px;">{{ language.RetrievePassword }}</div>
            <div style="flex:1;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <div style="text-align: center;max-width: 400px;">
                    {{ language.NewPasswordChange }}
                </div>
                <div style="margin-top:20px" v-if="language.language == '简体中文'">
                    {{ time }}{{ language.Jump }}
                </div>
                <div style="margin-top:20px" v-if="language.language == '繁體中文'">
                    {{ time }}{{ language.Jump }}
                </div>
                <div style="margin-top:20px" v-if="language.language == 'English'">
                    {{ language.Jump }} {{ time }} {{ language.Jump2 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../api/config';
import { smssend, userresetpwd, emssend } from '../../api/index.js';
export default {
    data() {
        return {
            language: "",//语言
            type: 0,//0:显示邮箱验证  1:输入新密码  2:修改成功跳转
            time: 3,//倒计时
            Email: "",//邮箱
            VerificationCode: "",//邮箱验证码
            New_password: "",//新密码
            Confirm_Password: "",//确认密码
            timeopen: "",//计时器方便后续关闭
            passwordicon:true,//显示密码
            passwordicon2:true,//显示密码
            showtitle: true
        };
    },
    created() {
        if (config.BaseUrl.indexOf("test") > 0) {
      this.showtitle = true
    } else {
      this.showtitle = false
    }
        // 判断语言
        this.changelanguage()
    },
    watch: {
        // 判断语言
        "$store.state.language"() {
            this.changelanguage()
        },
    },

    methods: {
        //密码隐藏及显示
    eyebtn() {
      this.passwordicon = !this.passwordicon
    },
    eyebtn2() {
      this.passwordicon2 = !this.passwordicon2
    },
        // 验证邮箱
        send() {
            if (!this.Email) {
                this.$message({
                    message: this.language.nomobile_number,
                    type: 'warning'
                });
                return
            }
            let data = {
                email: this.Email,
                event: "resetpwd"
            }
            emssend(data).then(res => {
                if (res.code == 1) {
                    this.type = 1
                }
            })
        },
        // 验证手机按钮
        send2() {
            if (!this.Email) {
                this.$message({
                    message: this.language.nomobile_number,
                    type: 'warning'
                });
                return
            }
            let data = {
                mobile: this.Email,
                event: "resetpwd"
            }
            smssend(data).then(res => {
                if (res.code == 1) {
                    this.type = 1
                }
            })
        },
        // 修改密码
        ClickModify() {
            if (!this.VerificationCode) {//未输入验证码
                this.$message({
                    message: this.language.noVerificationCode,
                    type: 'warning'
                });
                return
            }
            if (!this.New_password) {//未输入密码
                this.$message({
                    message: this.language.noNew_password,
                    type: 'warning'
                });
                return
            }
            if (!this.Confirm_Password) {//未输入确认密码
                this.$message({
                    message: this.language.noConfirm_Password,
                    type: 'warning'
                });
                return
            }
            // let data = {
            //     mobile:this.Email,
            //     type:"mobile",
            //     captcha:this.VerificationCode,
            //     newpassword:this.New_password
            // }
            let data = {
                email: this.Email,
                type: "email",
                captcha: this.VerificationCode,
                newpassword: this.New_password
            }
            userresetpwd(data).then(res => {
                if (res.code == 1) {
                    this.type = 2
                    this.settimeInterval()
                }
            })
        },
        // 修改成功倒计时跳转
        settimeInterval() {
            let that = this
            that.time = 3
            this.timeopen = setInterval(() => {
                that.time = that.time - 1
                if (that.time == 0) {
                    this.$router.push('/login')
                    clearInterval(that.timeopen)
                }
            }, 1000)
        },
        // 获取语言
        changelanguage() {
            this.language = this.$units()
        },
        // 切换语言
        handlanguage(command) {
            if (command == "CHS") {
                this.language = "简体中文"
            } else if (command == "TC") {
                this.language = "繁體中文"
            } else if (command == "EN") {
                this.language = "English"
            }
            this.$store.commit("changelanguage", command)
            this.changelanguage()
            // this.$message('click on item ' + command);
        },
    },
    destroyed() {
        // 页面销毁取消计时器
        clearInterval(this.timeopen)
    },
}

</script>
<style scoped>
.register_page {
    width: 100%;
    height: 100%;
    background-image: url("../../static/loginpagebg.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.register_box {
    background: #fff;
    border: 1px solid #efefef;
    padding: 20px 100px 50px 100px;
}

.register_title {
    font-family: SourceHanSansCN-Regular;
    font-size: 30px;
    font-weight: 400;
    font-stretch: normal;
    letter-spacing: 0;
    color: #fff;
    line-height: 117px;
    text-align: center;
}

.register_input {
    margin-top: 10px;
    width: 300px;
}

.register_success_box {
    width: 502px;
    height: 496px;
    padding: 0 !important;
    padding-top: 20px !important;
    display: flex;
    flex-direction: column;
}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
}

.el-icon-arrow-down {
    font-size: 12px;
}
.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}
</style>